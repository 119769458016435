<div class="card">
	<div class="card-block">
		<h4 class="card-title">Consolidated Electric</h4>

		<ul class="media-list">
		  <li class="media">
		    <div class="media-left">
		        <img class="media-object" src="/assets/icons/vox_gtr.svg">
		        <!-- <img class="media-object" src="/assets/icons/vocals.svg"> -->
		    </div>
		    <div class="media-body">
		      <p>Lewis Ames</p>
		    </div>
		  </li>
		  <li class="media">
		    <div class="media-left">
		        <img class="media-object" src="/assets/icons/vox_keys.svg">
		        <!-- <img class="media-object" src="/assets/icons/vocals.svg"> -->
		    </div>
		    <div class="media-body">
		      <p>Al Wollmer</p>
		    </div>
		  </li>
		  <li class="media">
		    <div class="media-left">
		        <img class="media-object" src="/assets/icons/bass_vox.svg">
		    </div>
		    <div class="media-body">
		      <p>Cyrus McGuire</p>
		    </div>
		  </li>
		  <li class="media">
		    <div class="media-left">
		        <img class="media-object" src="/assets/icons/drums.svg">
		    </div>
		    <div class="media-body">
		      <p>Ian Sutherland</p>
		    </div>
		  </li>
		</ul>

		<p class="card-text">
			Like lightning shooting out of a feverishly inspired mind, Consolidated Electric makes music that startles and illuminates, jolting even the most jaded listener awake. The 20,000 Megawatt sound can dazzle, unsettle and delight, sometimes simultaneously. That’s the uncanny power of Consolidated Electric.
		</p>
    <div class="media">
      <div class="media-left social-links">
				<a href="mailto:consolidated.electric.music@gmail.com">consolidated.electric.music@gmail.com</a>
        <a href="https://www.instagram.com/conelectricmus/" target="_blank" rel="noopener noreferrer"><img src="/assets/icons/Instagram_simple_icon.svg"></a>
        <a href="https://twitter.com/ConElectricMus1" target="_blank" rel="noopener noreferrer"><img src="/assets/icons/Twitter_Social_Icon_Circle_Color.svg"></a>
        <a href="https://www.facebook.com/Consolidated-Electric-370950866260610/" target="_blank" rel="noopener noreferrer"><img src="/assets/icons/facebook-2.svg"></a>
      </div>
    </div>
	</div>
</div>

<icon-credits></icon-credits>
