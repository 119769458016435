import { Component } from '@angular/core';
import { fadeAnimation } from '../animations/animations';

@Component({

	selector: 'icon-credits',
    templateUrl: 'icon-credits.html',
    styleUrls: ['icon-credits.css'],
    animations: [
    	fadeAnimation
    ]
})
export class IconCreditsComponent {

	public showCredits = false;

	public icons: string[] = [
		// '/assets/credits/dodecahedron.svg',
		'/assets/credits/drums.svg',
		// '/assets/credits/grid.svg',
		// '/assets/credits/noun_1847.svg',
		'/assets/credits/noun_32006_cc.svg',
		'/assets/credits/noun_60787_cc.svg',
		'/assets/credits/noun_67422_cc.svg',
		'/assets/credits/noun_128030_cc.svg',
		'/assets/credits/noun_157051_cc.svg',
		'/assets/credits/noun_198795_cc.svg',
		// '/assets/credits/noun_233253_cc.svg',
		'/assets/credits/noun_486685_cc.svg',
		'/assets/credits/noun_488379_cc.svg',
		'/assets/credits/noun_539441_cc.svg',
		// '/assets/credits/noun_589798_cc.svg',
		'/assets/credits/noun_641055_cc.svg',
		'/assets/credits/power-ball.svg'
	];

}


