import { Component } from '@angular/core';
import { FooterService } from '../footer-player/footer-service';
import { fadeAnimation } from '../animations/animations';

@Component({

    selector: 'turns-on-player',
    templateUrl: 'turns-on.html',
    styleUrls: ['turns-on.css'],
    animations: [
    	fadeAnimation
    ]
})
export class TurnsOnComponent {

	public showReview = false;

	constructor(private footer: FooterService) {}

	showFooter() {
		this.footer.toggleFooter(true);
	}

}


