import { Component } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { fadeAnimation } from "../animations/animations";

@Component({

    templateUrl: 'video.html',
    styleUrls: ['video.css'],
    animations: [fadeAnimation]
})
export class VideoComponent {


	public get openVideoIndex():number|boolean {
		let index:number|boolean = false;
		this.videos.map((val, i) => {if(val.visible) {index = i}});
		return index;
	}
	public videos: Array<{icon: string, title: string, url: SafeResourceUrl, visible: boolean}>;

	constructor(public sanitizer: DomSanitizer) {

		this.videos = [
      {
        title: "Wax Philosphical | Tiny Desk 2018 Contest",
        url: "https://www.youtube.com/embed/YaEy0vg6iqk",
        icon: "/assets/icons/film.svg",
        visible: false,
      },
      {
        title: "Live @ Hemlock Tavern 360º video",
        url: "https://www.youtube.com/embed/videoseries?list=PLJ_RlmwnTWk7SXExBjA9ryBwYlh2yX-P2",
        icon: "/assets/icons/grid.svg",
        visible: false
      },
			{
				title: "Molly Mullally's Psychedelic Steamboat",
				url: "https://www.youtube.com/embed/H0aKv0ViB18",
				icon: "/assets/icons/steamboat.svg",
				visible: false
			},
			{
				title: "Welcome Home (El Rio 2014)",
				url: "https://www.youtube.com/embed/cBTGfW35uJY",
				icon: "/assets/icons/connect.svg",
				visible: false
			},
			{
				title: "Fourth of Julie (El Rio 2014)",
				url: "https://www.youtube.com/embed/cmEaVWfhc7g",
				icon: "/assets/icons/connect.svg",
				visible: false
			}


		]
	}

	toggleVideo(index:string) {
		console.log(index);
		for(let i in this.videos) {
			if(this.videos[i].visible) this.videos[i].visible = false;
			else this.videos[i].visible = (i == index) ? true : false;
		}
	}

}


