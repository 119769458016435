import { Injectable } from '@angular/core';
import { Subject }    from 'rxjs';
@Injectable()
export class FooterService {
  // Observable boolean sources
  private showFooterSource = new Subject<boolean>();

  // Observable boolean streams
  showFooter$ = this.showFooterSource.asObservable();
  // Service message commands
  toggleFooter(state: boolean) {
    this.showFooterSource.next(state);
  }

}