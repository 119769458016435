import  { trigger, state, style, transition, animate } from '@angular/animations';

export const fadeAnimation =

trigger('fade', [
  state('in', style({opacity: 1})),
  state('void', style({opacity: 0})),
  transition('void => *', [
    animate('250ms ease-in-out')
  ])
]);


export const slowFadeAnimation =

trigger('slowFade', [
  state('in', style({opacity: 1})),
  state('void', style({opacity: 0})),
  transition('void => *', [
    animate('750ms ease-in-out')
  ])
]);