<div class="card full">
  <div class="card-block toggle" *ngIf="!showReview" [@fade]="showReview">
    <h4 class="card-title">Recordings</h4>
    <div style="margin-bottom: 1rem;">
      <p class="card-text">Ohm our second record.</p>
      <iframe style="border: 0; width: 350px; height: 470px;" src="https://bandcamp.com/EmbeddedPlayer/album=2115948166/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/transparent=true/" seamless><a href="http://consolidatedelectric.bandcamp.com/album/ohm">Ohm by Consolidated Electric</a></iframe>
    </div>
    <div class="media">
      <div class="media-left">
        <img src="/assets/img/turns-on.jpg">
      </div>
      <div class="media-body">
        <p class="card-text">Turns On is our first record.</p>
        <p class="card-text">
          <button type="button" class="btn btn-outline-primary" (click)="showFooter()">Listen</button>
        </p>
        <p class="card-text">
          <button type="button" class="btn btn-outline-primary" (click)="showReview = !showReview">Read a review
          </button>
        </p>


      </div>
    </div>


  </div>

  <div class="card-block toggle" *ngIf="showReview" [@fade]="showReview">
    <h4 class="card-title">Consolidated Electric – Turns On</h4>
    <h6 class="card-subtitle text-muted">Review by Barry Alfonso</h6>

    <p
      class="card-text">Puckish prog band or indie rock auteurs? Tweaked sunshine-pop outfit or quirky psychedelic revivalists? Bay Area foursome Consolidate Electric mix and match any number of recognizable styles on their debut album Turns On. Ultimately, they seem too creatively unfettered to allow themselves to be content with a single set of reference points. In this era of the musical microgenre, the sheer willfulness of this band’s refusal to aim for a narrow target is impressive – and refreshing. </p>

    <p
      class="card-text">In some key respects, Consolidated Electric is a throwback to an earlier era of rock album-making, when artists forged new combinations of sounds and sensibilities that were genuinely experimental. There are echoes of ‘60s rock units like Procol Harum and Pink Floyd to be heard in Turns On’s tracks, especially in the orchestral scope of the arrangements (as well as the use of Hammond organ). Hints of Van Dyke Parks-style studio filigree, Steely Dan-ish subversive jazz and XTC-like hyperactive pop can be found here as well. Added to all this is a theatrical strain that conjures up images of some dark European cabaret just before the Great War. If all this sounds like too rich a confection, the band lightens it with humor and a certain idealism that manifests itself in unexpected ways. Turns On is definitely more than the sum of its diverse influences.</p>

    <p
      class="card-text">The album benefits from the contrasting styles of its two songwriters. Lewis Ames (who sings and plays guitars as well as occasional trombone, bass and synthesizer) has a bittersweet, sometimes country-influenced quality to his work that finds expression in poignant story-songs (“Ways of Forgetting”), morbidly funny character sketches (“Bring Out Yer Dead”) and buoyant yet disquieting instrumentals (“Hannah Without Bird”). Albert Wollmer (who also sings and plays keyboards along with ukulele, mandocello and kazoo) shows an affinity for classic rock flourishes and theater music motifs, as well as a lyric bent that can be both luminously spiritual (“Gardener”), teasingly droll (“Fourth of Julie”) or erotically sinister (“Lady Thompson”). What the two of them share in common is a sophisticated lyric sense along with an ability to craft substantial melodies that don’t get lost in sonic minutiae. Ames and Wollmer seem to balance each other stylistically in the manner of Lennon and McCartney or Becker and Fagan (without the personal volatility, perhaps).</p>

    <p
      class="card-text">Make no mistake, though – Consolidated Electric doesn’t sound like a mere vehicle for two ambitious composers. Turns On is very much an ensemble project, with bassist Cyrus McGuire and drummer Ian Sutherland contributing supple rhythmic underpinnings that keep the album well-anchored even in the most exotic sonic terrain. Much as Procol Harum did on albums like Shine On Brightly and A Salty Dog, Consolidated Electric builds a solid superstructure before adding the embellishments. When they do cut loose in the studio, they come up with some amazing touches, like the Beatles-esque aquatic effects on “Molly Mullally’s Psychedelic Steamboat” or the melding of electric guitar and theremin on “The Heft.” Combined with this attention to detail are moments of improvisation (like the mini-freakout at the end of “Adagio”) that let the arrangements breathe and catch fire.</p>

    <p
      class="card-text">Turns On has its cerebral qualities, encouraging deep exploration with a pair of clamp-on headphones. But there’s also considerable heart to be found here, along with a starry-eyed sense of wonder and outrage at the world’s injustices. “Wax Philosophical” is an example of the band’s more hopeful strain – it hints at transcendent (possibly chemically-induced) experiences over a buoyant, flute-shaded track. More confrontational is “Ecce Gubernator,” a rollicking shuffle that invites comparisons with vintage Dylan in its use of Biblical imagery and scorching attack on the eternal Powers That Be. These and other songs are the work of artists engaged in the world, not just creating art for their own amusement.</p>

    <p
      class="card-text">For all its rarefied flourishes, Turns On is an inviting work, playful and smile-inducing even as it beckons the listener to look beneath its appealing surfaces. With albums like this, Consolidated Electric has the potential to bring together – yes, even consolidate – a wide audience with its expansive vision of what rock can be. </p>

    <button type="button" class="btn btn-outline-primary" (click)="showReview = !showReview">close</button>
  </div>


</div>





