
	// t current time
	// b start val
	// c change in val
	// d duration

	export const linearTween = function(t:number, b:number, c:number, d:number):number {
		return c*t/d + b;
	};

	/** Accelerating from zero velocity
	*
	*	t current time
	*	b start val
	*	c change in val
	*	d duration
	*/
	export const easeInQuad = function(t:number, b:number, c:number, d:number):number {
		t /= d;
		return c*t*t + b;
	};

	/** Decelerating to zero velocity
	*
	*	t current time
	*	b start val
	*	c change in val
	*	d duration
	*/
	export const easeOutQuad = function(t:number, b:number, c:number, d:number):number {
		t /= d;
		return -c * t*(t-2) + b;
	};


	/** Accelerating to halfway, then deceleration
	*
	*	t current time
	*	b start val
	*	c change in val
	*	d duration
	*/
	export const easeInOutQuad = function(t:number, b:number, c:number, d:number):number {
		t /= d/2;
		if (t < 1) return c/2*t*t + b;
		t--;
		return -c/2 * (t*(t-2) - 1) + b;
	};

	/** Accelerating from zero velocity
	*
	*	t current time
	*	b start val
	*	c change in val
	*	d duration
	*/
	export const easeInCubic = function(t:number, b:number, c:number, d:number):number {
		t /= d;
		return c*t*t*t + b;
	};

	/** Decelerating to zero velocity
	*
	*	t current time
	*	b start val
	*	c change in val
	*	d duration
	*/
	export const easeOutCubic = function(t:number, b:number, c:number, d:number):number {
		t /= d;
		t--;
		return c*(t*t*t + 1) + b;
	};

	/** Accelerating to halfway, then deceleration
	*
	*	t current time
	*	b start val
	*	c change in val
	*	d duration
	*/
	export const easeInOutCubic = function(t:number, b:number, c:number, d:number):number {
		t /= d/2;
		if (t < 1) return c/2*t*t*t + b;
		t -= 2;
		return c/2*(t*t*t + 2) + b;
	};

	/** quartic easing in - accelerating from zero velocity
	*
	*	t current time
	*	b start val
	*	c change in cal
	*	d duration
	*/


	export const easeInQuart = function(t:number, b:number, c:number, d:number):number {
		t /= d;
		return c * t * t * t * t + b;
	};



	/** quartic easing out - decelerating to zero velocity
	*
	*	t current time
	*	b start val
	*	c change in cal
	*	d duration
	*/


	export const easeOutQuart = function(t:number, b:number, c:number, d:number):number {
		t /= d;
		t--;
		return -c * (t * t * t * t - 1) + b;
	};



	/** quartic easing in/out - acceleration until halfway, then deceleration
	*
	*	t current time
	*	b start val
	*	c change in cal
	*	d duration
	*/


	export const easeInOutQuart = function(t:number, b:number, c:number, d:number):number {
		t /= d / 2;
		if (t < 1) return c / 2 * t * t * t * t + b;
		t -= 2;
		return -c / 2 * (t * t * t * t - 2) + b;
	};


	/** quintic easing in - accelerating from zero velocity
	*
	*	t current time
	*	b start val
	*	c change in cal
	*	d duration
	*/


	export const easeInQuint = function(t:number, b:number, c:number, d:number):number {
		t /= d;
		return c * t * t * t * t * t + b;
	};



	/** quintic easing out - decelerating to zero velocity
	*
	*	t current time
	*	b start val
	*	c change in cal
	*	d duration
	*/


	export const easeOutQuint = function(t:number, b:number, c:number, d:number):number {
		t /= d;
		t--;
		return c * (t * t * t * t * t + 1) + b;
	};



	/** quintic easing in/out - acceleration until halfway, then deceleration
	*
	*	t current time
	*	b start val
	*	c change in cal
	*	d duration
	*/


	export const easeInOutQuint = function(t:number, b:number, c:number, d:number):number {
		t /= d / 2;
		if (t < 1) return c / 2 * t * t * t * t * t + b;
		t -= 2;
		return c / 2 * (t * t * t * t * t + 2) + b;
	};


	/** sinusoidal easing in - accelerating from zero velocity
	*
	*	t current time
	*	b start val
	*	c change in cal
	*	d duration
	*/


	export const easeInSine = function(t:number, b:number, c:number, d:number):number {
		return -c * Math.cos(t / d * (Math.PI / 2)) + c + b;
	};



	/** sinusoidal easing out - decelerating to zero velocity
	*
	*	t current time
	*	b start val
	*	c change in cal
	*	d duration
	*/


	export const easeOutSine = function(t:number, b:number, c:number, d:number):number {
		return c * Math.sin(t / d * (Math.PI / 2)) + b;
	};



	/** sinusoidal easing in/out - accelerating until halfway, then decelerating
	*
	*	t current time
	*	b start val
	*	c change in cal
	*	d duration
	*/


	export const easeInOutSine = function(t:number, b:number, c:number, d:number):number {
		return -c / 2 * (Math.cos(Math.PI * t / d) - 1) + b;
	};



	/** exponential easing in - accelerating from zero velocity
	*
	*	t current time
	*	b start val
	*	c change in cal
	*	d duration
	*/


	export const easeInExpo = function(t:number, b:number, c:number, d:number):number {
		return c * Math.pow(2, 10 * (t / d - 1)) + b;
	};



	/** exponential easing out - decelerating to zero velocity
	*
	*	t current time
	*	b start val
	*	c change in cal
	*	d duration
	*/


	export const easeOutExpo = function(t:number, b:number, c:number, d:number):number {
		return c * (-Math.pow(2, -10 * t / d) + 1) + b;
	};



	/** exponential easing in/out - accelerating until halfway, then decelerating
	*
	*	t current time
	*	b start val
	*	c change in cal
	*	d duration
	*/


	export const easeInOutExpo = function(t:number, b:number, c:number, d:number):number {
		t /= d / 2;
		if (t < 1) return c / 2 * Math.pow(2, 10 * (t - 1)) + b;
		t--;
		return c / 2 * (-Math.pow(2, -10 * t) + 2) + b;
	};


	/** circular easing in - accelerating from zero velocity
	*
	*	t current time
	*	b start val
	*	c change in cal
	*	d duration
	*/


	export const easeInCirc = function(t:number, b:number, c:number, d:number):number {
		t /= d;
		return -c * (Math.sqrt(1 - t * t) - 1) + b;
	};



	/** circular easing out - decelerating to zero velocity
	*
	*	t current time
	*	b start val
	*	c change in cal
	*	d duration
	*/


	export const easeOutCirc = function(t:number, b:number, c:number, d:number):number {
		t /= d;
		t--;
		return c * Math.sqrt(1 - t * t) + b;
	};



	/** circular easing in/out - acceleration until halfway, then deceleration
	*
	*	t current time
	*	b start val
	*	c change in cal
	*	d duration
	*/


	export const easeInOutCirc = function(t:number, b:number, c:number, d:number):number {
		t /= d / 2;
		if (t < 1) return -c / 2 * (Math.sqrt(1 - t * t) - 1) + b;
		t -= 2;
		return c / 2 * (Math.sqrt(1 - t * t) + 1) + b;
	};
