import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home';
import { TurnsOnComponent } from './turns-on/turns-on';
import { AboutComponent } from './about/about';
import { VideoComponent } from './video/video';
import { ShowsComponent } from './shows/shows';



const routes: Routes = [
	{
		path: '',
		pathMatch: 'full',
		redirectTo: 'home'
	},
	{
		path: 'home',
		component: HomeComponent
	},
	{
		path: 'turns-on',
		component: TurnsOnComponent
	},
	{
		path: 'about',
		component: AboutComponent
	},
	{
		path: 'video',
		component: VideoComponent
	},
	{
		path: 'shows',
		component: ShowsComponent
	}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
