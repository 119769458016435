import { Component, Inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { slowFadeAnimation } from '../animations/animations';
import { easeInCirc } from '../animations/easing.service';


interface RGB {
	r: number,
	g: number,
	b: number
}

class FunObj {
	public rotation: number = 0;
	public color: RGB = {r: 220, g: 237, b: 206};
	public get colorCss():string {
		return `rgb(${this.color.r},${this.color.g},${this.color.b})`;
	}
	public get transform(): string {
		if(this.scaleEffect)
			return 'rotate('+this.rotation + 'deg) scale('+this.scale+')';
		else
			return 'rotate('+this.rotation + 'deg) scaleY('+this.skew+')';
	}
	public get scale():number {
		return Math.max(1, 1 + (this.velocity/(this.velocityLimit) * 0.4));
	}

	public get skew():number {
		return Math.max(0.1, 1 - this.velocity/(this.velocityLimit)) ;
	}


	public scaleEffect = false;
	public direction: number = 1;
	public velocity: number = 0;
	public maxVelocity: number = 0;
	public delta: number;

	// public animationDuration:number = 176;
	public get animationDuration():number {
		return (this.maxVelocity * 176) + 2500;
	}
	public animationTime:number = 0;

	public mouseOver = false;
	public seedValue: number = 0;
	public velocityLimit = 35;
	public colorOffset:RGB = {r:0, g:0, b:0};
	public get running(): boolean {
		return this.velocity > 0 || this.mouseOver;
	}

	constructor(props:{rotation?: number, color?: string, velocity?: number}) {
		for(let key in props) {
			this[key] = props[key];
		}
	}

	/* cycle animations */
	tick(delta: number) {
		if(this.running) {

			let lastV = this.velocity;
			this.delta = delta;

			this.cycleColors();
			this.rotate();

			if(this.mouseOver) {
				this.animationTime = 0;
				this.velocity = this.velocity + 1;
				this.velocity = Math.min(this.velocity, this.velocityLimit);

				this.scaleEffect = true;
				this.colorOffset = {r: 80, g: 50, b: 70}
			} else {
				this.velocity = easeInCirc(this.animationTime, this.velocity, 0 - this.velocity, this.animationDuration);
			}

			this.maxVelocity = Math.max(this.velocity, this.maxVelocity);

			if(this.velocity <= 0.02) {
				this.velocity = 0;
				this.direction = -1 * this.direction;
				this.animationTime = 0;
				this.maxVelocity = 0;

			}
			this.animationTime += delta;
		}
	}

	cycleColors() {
	    var frequency = .003;


	    this.color.r = Math.floor(Math.sin(frequency*this.seedValue + this.colorOffset.r ) * 90 + 128);
	    this.color.g = Math.floor(Math.sin(frequency*this.seedValue + this.colorOffset.g ) * 90 + 128);
	    this.color.b = Math.floor(Math.sin(frequency*this.seedValue + this.colorOffset.b ) * 90 + 128);

	    this.seedValue += (this.velocity/2) * this.direction;
	    // this.color = 'rgb('+colors.r+','+colors.g+','+colors.b+')';
	}

	rotate() {
		this.rotation += (this.velocity * this.direction);
	}
}

@Component({

	selector: 'fun-box',
	animations: [slowFadeAnimation],
	styleUrls: ['fun-box.css'],
    template: `
<div class="fun-box-container"
	[@slowFade]="fadeIn">
	<svg *ngFor="let element of elements; let i = index"
		[style.fill]="elements[i].colorCss"
		[style.transform]="sanitizer.bypassSecurityTrustStyle(elements[i].transform)"
		(mouseenter)="mouseOver(i)"
		(mouseleave)="mouseOut(i)"
		(touchstart)="mouseOver(i)"
		(touchend)="mouseOut(i)"
		class="electric"
		xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 512 512" enable-background="new 0 0 512 512" xml:space="preserve"><path d="M256,0C114.615,0,0,114.615,0,256s114.615,256,256,256s256-114.615,256-256S397.385,0,256,0z M316,179.5h-46.5V226h-27  v-46.5H196v-27h46.5V106h27v46.5H316V179.5z"/></svg>
</div>
    `,
    // styleUrls: ['app.component.css']
})
export class FunBoxComponent {

	public numberOfElements = 160;
	public fadeIn = false;

	public elements: FunObj[] = [];
	public running = false;
	public frame: number;
	public lastTimestamp: number;
	public isTouchDevice = 'ontouchstart' in document.documentElement;

	constructor(public sanitizer: DomSanitizer) {}

	ngOnInit() {

		for(let i = 0; i < this.numberOfElements; i++) {
			let rotation = Math.tan(i / this.numberOfElements) * 100;
			let v = (i/2)+5;
			let f = new FunObj({rotation: rotation, velocity:v});

			// if(this.isTouchDevice) {
				let c = Math.floor(Math.sin(0.003*v) * 90 + 128)
				f.color.r = c;
				f.color.g = c;
				f.color.b = c;
				// console.log(f.color);
			// }
			this.elements.push(f);
		}

		this.running = true;


		setTimeout(() => this.fadeIn, 250);
		setTimeout(() => this.animate(), 1000);

	}

	randomInt(lower: number, upper:number) {
	  return (Math.floor(Math.random() * (lower - upper)) + upper) / lower;
	}

	mouseOver(index:number) {
		// turn on running bit
		// write anin fn with RAF
		this.elements[index].mouseOver = true;

		// this.elements[index].tick();
	}

	mouseOut(index:number) {
		this.elements[index].mouseOver = false;
	}

	start() {
		// console.log("called");
	}

	animate(timestamp = Date.now()) {
		let self = this;
		if(!this.lastTimestamp) this.lastTimestamp = timestamp;
		let delta = timestamp - this.lastTimestamp;
		if(!delta) delta = 50;
		if(this.running) {
			for(let i in this.elements) {
				this.elements[i].tick(delta);
				// if(i == "150") console.log(this.elements[i].maxVelocity, this.elements[i].velocity, this.elements[i].animationDuration);
			}
			this.frame = window.requestAnimationFrame(() => self.animate());
		}
		this.lastTimestamp = timestamp;
	}

	endAnimation() {
		this.running = false;
		window.cancelAnimationFrame(this.frame);
	}

	ngOnDestroy() {
		this.endAnimation();
	}




}
