import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TurnsOnComponent }  from './turns-on/turns-on';
import { HomeComponent } from './home/home';
import { AboutComponent } from './about/about';
import { IconCreditsComponent } from './icon-credits/icon-credits';
import { ShowsComponent } from './shows/shows';
import { VideoComponent } from './video/video';
import { NavComponent } from './nav/main-nav';
import { FooterPlayerComponent } from './footer-player/footer-player';
import { FooterService } from './footer-player/footer-service';
import { FunBoxComponent } from './fun-box/fun-box';

@NgModule({
  declarations: [
    AppComponent,
    TurnsOnComponent,
  	HomeComponent,
  	NavComponent,
  	AboutComponent,
  	ShowsComponent,
  	IconCreditsComponent,
  	VideoComponent,
  	FunBoxComponent,
  	FooterPlayerComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule
  ],
  providers: [ FooterService ],
  bootstrap: [AppComponent]
})
export class AppModule { }
