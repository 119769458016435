<div class="card">
	<div class="card-block">
		<h4 class="card-title">Icon Credits</h4>
        <h6 class="card-subtitle text-muted" [@fade]="showCredits" *ngIf="showCredits">All icons from the Noun Project</h6>

        <button
        	type="button"
        	class="btn btn-outline-primary"
        	*ngIf="!showCredits"
        	(click)="showCredits = !showCredits"
        	[@fade]="showCredits">Show credits</button>

		<ul [@fade]="showCredits" *ngIf="showCredits">
			<li *ngFor="let icon of icons;"><img [src]="icon" /></li>
		</ul>
		<button
        	type="button"
        	class="btn btn-outline-primary"
        	*ngIf="showCredits"
        	(click)="showCredits = !showCredits"
        	[@fade]="showCredits">Ok</button>
	</div>
</div>