<div class="overlay"
	[@fadeOverlay]="playerState"
	[class.active]="showOverlay"
	(click)="toggleClick()"></div>
<button class="player-button" id="player-button" (click)="toggleClick()">
	<img *ngIf="playerState=='out'" src="assets/icons/speaker-green.svg">
	<img *ngIf="playerState=='in'" src="assets/icons/speaker-grey.svg">
</button>
<footer>
	<div class="container">
		<div class="card" [@bounceInOut]="playerState">
		  <div class="card-block">
		  	<div *ngIf="!loadPlayer" class="loader"><img src="/assets/icons/electric.svg"><p>Loading...</p></div>

			<iframe *ngIf="loadPlayer" width="100%" height="450" scrolling="no" frameborder="no" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/16438203&amp;color=000000&amp;auto_play=false&amp;hide_related=false&amp;show_comments=true&amp;show_user=true&amp;show_reposts=false"></iframe>
		  </div>
		</div>
	</div>
</footer>