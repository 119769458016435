import { Component } from '@angular/core';
import { trigger, state, style, transition, animate, keyframes } from '@angular/animations';
import { FooterService } from './footer-service';


@Component({

    selector: 'footer-player',
    templateUrl: 'footer-player.html',
    styleUrls: ['footer-player.css'],
    animations: [
    	trigger('fadeOverlay', [
		  state('in', style({opacity: 0.30})),
		  state('out', style({opacity: 0})),
		  transition('out <=> in', [
		    animate('500ms ease-in-out')
		  ]),
		]),
		trigger('bounceInOut', [
		    state('in', style({transform: 'translateY(-76vh)'})),
		    transition('out => in', [
		      animate(300, keyframes([
		        style({transform: 'translateY(0) scaleY(1)', offset: 0}),
		        style({transform: 'translateY(-78vh) scaleY(1.03)',  offset: 0.3}),
		        style({transform: 'translateY(-76vh) scaleY(1)',     offset: 1.0})
		      ]))
		    ]),
		    transition('in => out', [
		      animate(300, keyframes([
		        style({transform: 'translateY(-76vh) scaleY(1)',     offset: 0}),
		        style({transform: 'translateY(-80vh) scaleY(1.05)', offset: 0.7}),
		        style({transform: 'translateY(0) scaleY(1)',  offset: 1.0})
		      ]))
		    ])
		  ])
    ]
})

export class FooterPlayerComponent {
	public playerState:string = 'out';
	public showOverlay:boolean = false;
	public loadPlayer = false;

	constructor(private footer: FooterService) {}

	ngOnInit() {
		this.footer.showFooter$.subscribe(
			(s) => {

				this.togglePlayer();

				if(s && !this.loadPlayer) this.loadPlayer = true;
			}
		);
	}

	// trigger footer service
	public toggleClick() {
		let s = !this.showOverlay;
		this.footer.toggleFooter(s);
	}

	public togglePlayer() {
		this.playerState = this.playerState == 'out' ? 'in' : 'out';

		if(this.playerState == 'in')
			this.showOverlay = true;
		else {
			setTimeout(() => this.showOverlay = false, 500);
		}
	}
}


