import { Component } from '@angular/core';

@Component({
  selector: 'upcoming-shows',
  templateUrl: 'shows.html',
  styles: [`
      .show-poster {
          height: calc(74vh - 80px);
          border: 1px solid black;
      }

      @media screen and (max-width: 992px) {
          .show-poster {
              width: 100%;
              height: auto;
          }
      }
  `]
})
export class ShowsComponent {
}


