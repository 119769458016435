<div class="card full">
  <div class="card-block">

    <h4 class="card-title">Shows</h4>
    <p class="card-text"><a href="https://www.facebook.com/events/2209702832749198" target="_blank"
                            rel="noopener noreferrer">February 28th at O'Reilly's</a>
    </p>
    <img src="/assets/img/oreillys-flier.jpg" class="show-poster">
    <div class="card-text">
      <!-- <p>Join us for an evening of Americana Blues Pscyh Rock Fantasy Folk at the Starry Plough!</p> -->
      <p>We're on first at 8pm!!</p>
      <p>Buy Tickets <a href="https://www.facebook.com/events/2209702832749198" target="_blank"
                        rel="noopener noreferrer">here.</a></p>
    </div>
    <!-- <p class="card-text">Nothing lined up now, check back after COVID!</p> -->
  </div>
</div>
