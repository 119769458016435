<div class="card full">
  <div class="card-block">
    <h4 class="card-title">Videos</h4>
		<div *ngFor="let video of videos; let i = index;">
			<div class="media video-row"  *ngIf="openVideoIndex === false || openVideoIndex == i" [@fade]="openVideoIndex">
        <h4 class="media-heading" [class.active]="!video.visible">{{video.title}}</h4>
        <a class="media-left" (click)="toggleVideo(i)">
			    <div [class.active]="video.visible">
			    	<img [src]="video.icon">
			    </div>
			  </a>
			  <div class="media-body">
			    <h4 class="media-heading" [class.active]="video.visible">{{video.title}}</h4>
			    <div class="video-wrapper" [class.active]="video.visible">
			    	<iframe *ngIf="video.visible" width="560" height="315" [src]="sanitizer.bypassSecurityTrustResourceUrl(video.url)" frameborder="0" allowfullscreen></iframe>
			    </div>
			  </div>
			</div>
		</div>
	</div>
</div>
