<div class="container">
	<ul>
		<li>
			<a routerLinkActive="active" [routerLink]="['./home']">
				<img src="/assets/icons/diode.svg">
			</a>
			<div class="nav-title"  routerLinkActive="active" [routerLink]="['./home']">Home</div>
		</li>
		<li>
			<a routerLinkActive="active" [routerLink]="['./turns-on']">
				<img src="/assets/icons/record.svg">
			</a>
			<div class="nav-title"  routerLinkActive="active" [routerLink]="['./turns-on']">Listen</div>
		</li>
		<li>
			<a routerLinkActive="active" [routerLink]="['./shows']">
				<img src="/assets/icons/vocals.svg">
			</a>
			<div class="nav-title" routerLinkActive="active" [routerLink]="['./shows']" >Shows</div>
		</li>
		<li>
			<a routerLinkActive="active" [routerLink]="['./about']">
				<img src="/assets/icons/team.svg">
			</a>
			<div class="nav-title" routerLinkActive="active" [routerLink]="['./about']" >About</div>
		</li>
		<li>
			<a routerLinkActive="active" [routerLink]="['./video']">
				<img src="/assets/icons/film.svg">
			</a>
			<div class="nav-title" routerLinkActive="active" [routerLink]="['./video']" >Video</div>
		</li>

	</ul>
</div>